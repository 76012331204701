import React from 'react'

const Footer = () => {
    return (
      <div className=" px-3 py-5 font-thin text-gray-100">
        <p>Built with React and Tailwind</p>
      </div>
    );
  };

export default Footer